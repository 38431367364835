import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { EventListing } from '@wbk/api';
import { Button, ImageWithFallback } from '@wbk/ui';
import { useAnalytics } from '@wbk/analytics';
import { formatDate } from '@wbk/utils';

type Props = {
  event: EventListing;
} & React.HTMLAttributes<HTMLElement>;

const EventListItem = ({ event, ...props }: Props) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { generateLeadEvent } = useAnalytics();

  return (
    <Button
      as={Link}
      theme='secondary'
      href={`/event/${event.slug}`}
      onClick={() => {
        generateLeadEvent({
          event_start_price: event.event_start_price + event.event_start_vat,
          currency: event.currency,
          title: event.title,
          slug: event.slug,
        });
      }}
      className='flex w-full grow flex-col items-center gap-6 p-4 md:flex-row'
      {...props}
    >
      <div className='flex w-full grow justify-center gap-4 md:items-center'>
        <div className='hidden w-20 truncate rounded-md bg-white/10 p-2 text-center leading-none md:block'>
          {event.tbc ? (
            <p className='py-4'>TBC</p>
          ) : (
            <>
              <p className='text-sm'>
                {formatDate(event.start_date_time, locale, { month: 'long' })}
              </p>
              <p className='-my-1 text-3xl font-bold'>
                {formatDate(event.start_date_time, locale, { day: '2-digit' })}
              </p>
              <p className='text-sm'>
                {formatDate(event.start_date_time, locale, { year: 'numeric' })}
              </p>
            </>
          )}
        </div>

        <div className='flex w-full flex-col items-center gap-6 md:flex-row'>
          <div className='relative flex aspect-[3/2] w-full overflow-hidden rounded-md md:h-20 md:max-w-40'>
            <ImageWithFallback
              src={event?.poster}
              className='object-cover'
              sizes='220px'
              alt={event?.title || ''}
            />
          </div>

          <div className='space-y-1 text-start'>
            <p className='text-2xl md:text-xl lg:text-2xl'>
              <strong>{event.title}</strong>
            </p>
            {!event.tbc && (
              <p className='hidden font-bold md:block'>
                {formatDate(event.start_date_time, locale, { timeStyle: 'short' })}
              </p>
            )}
          </div>
        </div>
      </div>
      <Button
        className='w-full whitespace-nowrap text-xl font-bold md:max-w-[140px] md:!py-1 md:text-base'
        arrow
        tabIndex={-1}
        theme='action'
      >
        {t('event:buy_tickets')}
      </Button>
    </Button>
  );
};

export default EventListItem;
