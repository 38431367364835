import { useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { useSubscribe } from '@wbk/api/hooks';
import { WithGrecaptcha } from '@wbk/api/grecaptcha';
import { Button, Input } from '@wbk/ui';
import { EMAIL_PATTERN } from '@wbk/utils';
import Reveal from '../animation/Reveal';

const NewsLetterSection = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<{ email: string }>();
  const { mutateAsync, isPending, error } = useSubscribe();
  const email = watch('email') || '';

  const onSubmit = async (data: { email: string }) => {
    try {
      await mutateAsync({
        email: data.email,
        type: 'organization',
        slug: process.env.NEXT_PUBLIC_ORGANIZATION_SLUG!,
        interests: [process.env.NEXT_PUBLIC_ORGANIZATION_SLUG!],
      });
      setSuccess(true);
    } catch (error) {
      //
    }
  };

  return (
    <WithGrecaptcha>
      <Reveal as='section' direction='bottom' className='container mb-12 overflow-hidden'>
        <div className='relative flex flex-col-reverse rounded-lg bg-white md:flex-row'>
          <div className='z-10 grow p-4 md:px-6'>
            <p className='text-2xl font-bold uppercase text-black'>
              {t('common:newsletter.title')}
            </p>
            <p className='text-lg text-gray-600'>{t('common:newsletter.description')}</p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='mt-4 flex flex-col items-start gap-1 sm:flex-row'
            >
              <fieldset className='w-full grow md:max-w-md'>
                <Input
                  className='bg-gray-50 text-black hover:bg-gray-100 focus:bg-gray-100'
                  type='email'
                  placeholder={t('common:newsletter.email_placeholder')}
                  {...register('email', {
                    required: t('common:validation.required'),
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: t('common:validation.invalid_email'),
                    },
                  })}
                  onChange={(value) => {
                    setValue('email', value);
                  }}
                  error={errors.email?.message || error?.message}
                  value={email}
                  disabled={success}
                  hideErrorSpace
                />
                {success && (
                  <p className='pb-2 text-sm text-success'>{t('common:newsletter.success')}</p>
                )}
              </fieldset>

              <Button
                type='submit'
                loading={isPending}
                disabled={success}
                theme='primary'
                className='px-8 uppercase'
              >
                {t('common:newsletter.subscribe')}
              </Button>
            </form>
          </div>
          <div className='relative hidden w-24 -rotate-[25deg] scale-[2] bg-body-level-2 md:block lg:w-12 ltr:left-6 rtl:right-6 rtl:rotate-[25deg]'></div>
          <div className='hidden grow items-center justify-center bg-body-level-2 px-4 py-10 md:flex md:py-4 ltr:rounded-r-md rtl:rounded-l-md'>
            <Image
              className='relative h-24 object-contain'
              src='/icons/logo.svg'
              height={96}
              width={96}
              alt={t('common:seo.title')}
            />
          </div>
        </div>
      </Reveal>
    </WithGrecaptcha>
  );
};

export default NewsLetterSection;
