import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useEventsList } from '@wbk/api/hooks';
import EventListItem from '@/components/events/EventListItem';
import MatchListItem from '@/components/match/MatchListItem';
import NewsLetterSection from '@/components/common/NewsLetter';
import Fade from '@/components/animation/Fade';
import Banner from '@/components/banner/Banner';
import FootballSpinner from '@/components/ui/spinners/FootballSpinner';
import DeflatedBallWrapper from '@/components/error/DeflatedBallWrapper';
import { GLOBAL_REVALIDATE } from '../constants/revalidate';

const HomePage = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { data, isLoading, error } = useEventsList({
    lang: locale,
    publicOnly: true,
    organizationSlug: process.env.NEXT_PUBLIC_ORGANIZATION_SLUG!,
  });

  if (isLoading) {
    return (
      <div className='mx-auto my-20 max-w-lg space-y-4'>
        <FootballSpinner className='text-primary-dark mx-auto h-80 w-80' />
      </div>
    );
  }

  if (error || !data) {
    return (
      <DeflatedBallWrapper>
        <div className='mx-auto max-w-xl rounded-lg bg-secondary-400/80 px-6 py-10 shadow-md'>
          <p className='mb-2 text-center text-3xl text-error'>
            {error?.message || 'Something went wrong'}
          </p>
        </div>
      </DeflatedBallWrapper>
    );
  }

  return (
    <>
      <NextSeo
        title={t('tickets:seo.title')}
        description={t('tickets:seo.description')}
        additionalMetaTags={[{ name: 'keywords', content: t('home:seo.keywords') }]}
        openGraph={{
          images: [
            {
              alt: `${t('common:title')}`,
              url: 'https://tickets.ittihadclub.sa/images/default-cover.jpg',
            },
          ],
        }}
      />

      <section className='container py-10 pt-4 md:pt-10'>
        <Banner />
      </section>

      <section className='container py-12'>
        <h2 className='pb-8 text-4xl font-bold text-white'>{t('event:upcoming_events')}</h2>
        {data.events.length > 0 ? (
          <ul className='space-y-4' data-testid='matches-list'>
            {data.events.map((event, i) => {
              const isMatch = !!event?.home_team?.name && !!event?.away_team?.name;
              return isMatch ? (
                <Fade key={event._id} as='li' duration={0.3} delay={i * 0.03}>
                  <MatchListItem event={event} data-list='match-list' data-list-name='home-page' />
                </Fade>
              ) : (
                <Fade key={event._id} as='li' duration={0.3} delay={i * 0.03}>
                  <EventListItem event={event} data-list='event-list' data-list-name='home-page' />
                </Fade>
              );
            })}
          </ul>
        ) : (
          <div className='px-4 py-12 text-center'>
            <Image
              className='mx-auto'
              src='/icons/common/no-matches.svg'
              width={219}
              height={184}
              alt='No matches'
            />
            <p>{t('tickets:scheduler_will_be_announced')}</p>
            <p>{t('tickets:meanwhile_you_can_newsletter')}</p>
          </div>
        )}
      </section>

      <NewsLetterSection />
    </>
  );
};

export const getStaticProps: GetStaticProps<LayoutProps> = async ({ locale = '' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'auth',
        'profile',
        'common',
        'tickets',
        'home',
        'event',
        'auth_common',
      ])),
    },
    revalidate: GLOBAL_REVALIDATE,
  };
};

export default HomePage;
